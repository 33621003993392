/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import { strict as o, JSONMap as r } from "../../core/jsonMap.js";
import { JSONSupport as t } from "../../core/JSONSupport.js";
import { equalsMaybe as s } from "../../core/maybe.js";
import { property as i } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { reader as n } from "../../core/accessorSupport/decorators/reader.js";
import { subclass as p } from "../../core/accessorSupport/decorators/subclass.js";
import { writer as u } from "../../core/accessorSupport/decorators/writer.js";
import f from "./FeatureExpressionInfo.js";
import { supportedUnits as a } from "./unitConversionUtils.js";
var l;
const m = o()({
    onTheGround: "on-the-ground",
    relativeToGround: "relative-to-ground",
    relativeToScene: "relative-to-scene",
    absoluteHeight: "absolute-height"
  }),
  d = new r({
    foot: "feet",
    kilometer: "kilometers",
    meter: "meters",
    mile: "miles",
    "us-foot": "us-feet",
    yard: "yards"
  });
let c = l = class extends t {
  constructor(e) {
    super(e), this.offset = null;
  }
  readFeatureExpressionInfo(e, o) {
    return null != e ? e : o.featureExpression && 0 === o.featureExpression.value ? {
      expression: "0"
    } : void 0;
  }
  writeFeatureExpressionInfo(e, o, r, t) {
    o[r] = e.write({}, t), "0" === e.expression && (o.featureExpression = {
      value: 0
    });
  }
  get mode() {
    const {
      offset: e,
      featureExpressionInfo: o
    } = this;
    return this._isOverridden("mode") ? this._get("mode") : null != e || o ? "relative-to-ground" : "on-the-ground";
  }
  set mode(e) {
    this._override("mode", e);
  }
  set unit(e) {
    this._set("unit", e);
  }
  write(e, o) {
    return this.offset || this.mode || this.featureExpressionInfo || this.unit ? super.write(e, o) : null;
  }
  clone() {
    return new l({
      mode: this.mode,
      offset: this.offset,
      featureExpressionInfo: this.featureExpressionInfo ? this.featureExpressionInfo.clone() : void 0,
      unit: this.unit
    });
  }
  equals(e) {
    return this.mode === e.mode && this.offset === e.offset && this.unit === e.unit && s(this.featureExpressionInfo, e.featureExpressionInfo);
  }
};
e([i({
  type: f,
  json: {
    write: !0
  }
})], c.prototype, "featureExpressionInfo", void 0), e([n("featureExpressionInfo", ["featureExpressionInfo", "featureExpression"])], c.prototype, "readFeatureExpressionInfo", null), e([u("featureExpressionInfo", {
  featureExpressionInfo: {
    type: f
  },
  "featureExpression.value": {
    type: [0]
  }
})], c.prototype, "writeFeatureExpressionInfo", null), e([i({
  type: m.apiValues,
  nonNullable: !0,
  json: {
    type: m.jsonValues,
    read: m.read,
    write: {
      writer: m.write,
      isRequired: !0
    }
  }
})], c.prototype, "mode", null), e([i({
  type: Number,
  json: {
    write: !0
  }
})], c.prototype, "offset", void 0), e([i({
  type: a,
  json: {
    type: String,
    read: d.read,
    write: d.write
  }
})], c.prototype, "unit", null), c = l = e([p("esri.symbols.support.ElevationInfo")], c);
const h = c;
export { h as default };