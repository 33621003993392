/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as r } from "../../chunks/tslib.es6.js";
import { JSONSupport as s } from "../../core/JSONSupport.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as o } from "../../core/accessorSupport/decorators/subclass.js";
import { collectArcadeFieldNames as t } from "../../layers/support/fieldUtils.js";
var i;
let p = i = class extends s {
  constructor(r) {
    super(r);
  }
  async collectRequiredFields(r, s) {
    return t(r, s, this.expression);
  }
  clone() {
    return new i({
      expression: this.expression,
      title: this.title
    });
  }
  equals(r) {
    return this.expression === r.expression && this.title === r.title;
  }
};
r([e({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "expression", void 0), r([e({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "title", void 0), p = i = r([o("esri.symbols.support.FeatureExpressionInfo")], p);
const n = p;
export { n as default };